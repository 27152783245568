.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: transparent;
    height: 48px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transition: background-color 0.3s ease;
  }
  
  .underline-link {
    position: relative;
    text-decoration: none;
    color: #fff;
  }
  
  .underline-link::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: black;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .underline-link:hover::after {
    transform: scaleX(1);
  }
  
  
  .logo-russo {
    width: 120px;
    height: 35.82px;
  }
  
  .navbar-menu {
    display: flex;
    gap: 1rem;
  }
  
  .navbar-item {
    color: #fff;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  
  
  .navbar-toggle,
  .navbar-close {
    display: none;
    color: #fff;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    outline: none;
    background-color: transparent;
    
  }
  
  .navbar-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 20px;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    
  }
  
 
  
  .scrolled .navbar-toggle-icon {
    background-color: #000;
  }
  

  
  .navbar--scroll {
    background-color: #f2f0e8;
  }
  
  .navbar--scroll .navbar-item {
    color: black;
  }
  

  
  .navbar-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  
  .navbar.scrolled {
    background-color: white;
  }
  
  .navbar-item {
    color: black;
  }
  
  .navbar.scrolled .navbar-item {
    color: #000;
  }

  .burger-icon{
    color: #000;
    z-index: 99;
    
    
  }
  
  @media (max-width: 1024px) {
    .navbar-menu {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: -100%;
      width: 250px;
      height: 100%;
      gap: 0;
      background-color: #f2f0e8;
      padding: 1rem;
      transition: right 0.3s ease-in-out;
    }
  
    .navbar-menu.open {
      right: 0;
      padding-top: 40px;
      width: 200px;
    }
  
    .navbar-item {
      margin-bottom: 1rem;
    }
  
    .navbar-toggle {
      display: flex;
      outline: none;
      background-color: transparent;
    }
  
    .navbar-close {
      display: block;
    }

    .underline-link:hover::after {
      transform: scaleX(0);
    }
  }
  
  @media (min-width: 1025px) {
    .navbar-toggle {
      display: none;
    }
  }
  