.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #000;
    color: #fff;
    text-align: center;
    z-index: 999999;
  }
  
  .cookie-banner-button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cookie-banner-button:hover {
    background-color: #333;
  }
  