.button {
    box-shadow: inset 0 0 0 0 white;
    color: white;
    padding: 0 .25rem;
    margin: 0 -.25rem;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  }
  .button:hover {
    color: black;
    box-shadow: inset 400px 0 0 0 white;;
  }
  
  /* Presentational styles */
  .button {
    color: white;
    text-decoration: none;
  }