.about-container {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    
  }
  
  header {
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
    padding-left: 8px;
    padding-right: 8px;
  }

  h1{
    text-align: center;
  }
  
  .photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;
    margin: 2rem 0;
  }
  
  .photo-gallery img {
    width: 100%;
    height: auto;
  }
  
  .text-section {
    text-align: center;
    margin: 2rem 0;
  }
  

  @media screen and (max-width: 768px) {
    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 30px;
    }
  }
  

  