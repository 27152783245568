.container {
    padding-top: 8rem;
    
    
}

.list-container{
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;
    

}

.lista-negozi{
    list-style: none;
    line-height: 60px;
    margin: 0px;
    padding: 0px;
}

a{
 text-decoration: none;
 color: black;
}