.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f0e8;
  margin-top: auto;
  padding-top: 10px;
}

/* f2f0e8 */

.social-icons {
  display: flex;
  justify-content: center;
}

.social-icons a {
  color: #000;
  margin-right: 1rem;
}

.social-icons a:last-child {
  margin-right: 0;
}

.partita-iva {
  font-size: 16px;
}

.note-legali {
  font-size: 13px;
  text-decoration: none;
  color: black;
}

.copyright {
  font-size: 11px;
}

a {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  /* Stili specifici per dispositivi più piccoli */
  .footer {
    padding-top: 20px;
  }
}
