.foto{
  height: 100%;
  display: block;
  margin: 0 auto; /* Centra l'immagine orizzontalmente */
  max-width: 80%;
  margin-bottom: 30px;
  border: solid 1px black;
}

.swiper-pagination-bullet-active {
    background-color: black /* Cambia il colore del bullet attivo a rosso */
  }