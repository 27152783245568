html {
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
