.content {
  text-align: center;
}

img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {
  /* Styles for screens up to 768px width */
  img {
    max-width: 100%;
    height: auto;
  }
}
