.container {
    padding-top: 4rem;
    
}

.title{
    justify-content: center;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
}

.subtitle{
    justify-content: center;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
}

.text{
   
    justify-content: center;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
}
