h1{
    text-align: center;
  }
  
  
  
  .container{
      padding-top: 4rem;
  }
  
  .form-container {
      max-width: 550px;
      margin: 0 auto;
      padding: 0 24px;
      
    }
  
  .formbold-mb-5 {
      margin-bottom: 20px;
      
      
      
      
    }
    .formbold-pt-3 {
      padding-top: 12px;
    }
    .formbold-main-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 48px;
    }
  
    .formbold-form-wrapper {
      margin: 0 auto;
      max-width: 550px;
      width: 100%;
      background: white;
  
    }
    .formbold-form-label {
      display: block;
      font-weight: 500;
      font-size: 16px;
      color: black;
      margin-bottom: 12px;
    }
    .formbold-form-label-2 {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 20px;
    }
  
    .formbold-form-input {
      width: 100%;
      padding: 12px 24px;
      border-radius: 6px;
      border: 1px solid #e0e0e0;
      background: white;
      font-weight: 500;
      font-size: 16px;
      color: #6b7280;
      outline: none;
      resize: none;
    }
    .formbold-form-input:focus {
      border-color: black;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
    }
  
    .formbold-btn {
      text-align: center;
      font-size: 16px;
      border-radius: 6px;
      padding: 14px 32px;
      border: none;
      font-weight: 600;
      background-color: black;
      color: white;
      cursor: pointer;
    }
    .formbold-btn:hover {
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
    }
  
    .formbold--mx-3 {
      margin-left: -12px;
      margin-right: -12px;
    }
    .formbold-px-3 {
      padding-left: 12px;
      padding-right: 12px;
    }
    .flex {
      display: flex;
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    .w-full {
      width: 100%;
    }
    @media (min-width: 540px) {
      .sm\:w-half {
        width: 50%;
      }
    }
  
    @media screen and (max-width: 768px) {
      .formbold-form-input, .formbold-form-label, textarea {
        width: 86%;
        margin: 0;
      }
  
      .formbold-btn {
          width: 40%;
      }
      .formbold-form-input {
        margin-bottom: 20px;
      }
      .formbold-main-wrapper {
        padding: 24px;
      }
    }