.home-container {
  position: relative;
  width: 100%;
  min-height: 100vh;

  
  
  
}





.background-video {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.9s ease-in;
}

.background-video.loaded {
  opacity: 1;
}




.double-arrow-icon {
  position: absolute;
  left: 50%;
  top: 90vh;
  transform: translateX(-50%);
  color: black;
  font-size: 30px;
  animation: pulse 2s infinite;
  z-index: 99;
}

@keyframes pulse {
  0% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateX(-50%) scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
}

.banner {
  background-color: black;
  color: white;
  min-height: 50vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.carousel-header {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .carousel-header {
    font-size: 2rem;
    text-align: center;
    padding: 0 40px;
  }

.double-arrow-icon{
  top: 80vh;
}
}